// Define the interface for the route object
interface IRouteObject {
  admin: {
    agency: {
      [key: string]: string;
    };
    globalAdmin: {
      [key: string]: string;
    };
    client: {
      [key: string]: string;
    };
    user: {
      [key: string]: string;
    };
    kpis: {
      [key: string]: string;
    };
    permissions: string;
    publishingRequirements: string;
    emailTemplates: string;
  };
  client: {
    [key: string]: string | { [key: string]: string };
    kpis: {
      [key: string]: string;
    };
    dataSets: {
      [key: string]: string;
    };
  };
  invitation: {
    [key: string]: string;
  };
  common: {
    [key: string]: string;
  };
}

export const routes = {
  admin: {
    agency: {
      list: "/agency",
      addAgency: "/agency/add",
    },
    globalAdmin: {
      list: "/global-admin",
      add: "/global-admin/add",
    },
    client: {
      list: "/clients",
      addClient: "/clients/add",
      editClient: "/clients/[clientId]/edit",
      addProfile: "/clients/[clientId]/profile",
      editProfile: "/clients/[clientId]/profile",
      details: "/clients/[clientId]/details",
    },
    user: {
      list: "/hub-users",
      addTGGUser: "/hub-users/add",
      userDetail: "/hub-users/[userId]",
    },
    kpis: {
      list: "/kpis",
      kpiDetails: "/kpis/[kpiId]",
    },
    permissions: "/permissions",
    publishingRequirements: "/publishing-requirements",
    emailTemplates: "/email-templates",
  },
  client: {
    dashboard: "/details",
    clientUserDashboard: "/dashboard",
    landing: "/landing",
    details: "/details",
    editClient: "/edit-client",
    editProfile: "/edit-profile",
    accounts: "/accounts",
    documents: "/documents",
    folderView: "/documents/[folderId]/view",
    accountAIS: "/ais-accounts",
    aisAccountsList: "/ais-accounts/list",
    accountCOA: "/accounts/chart-of-accounts",
    qbSetup: "/qb-setup",
    qbSetupQBD: "/qb-setup/setup-qbd",
    accountMapAIS: "/ais-accounts/map-ais-accounts",
    isClassification: "/is-classification",
    reports: "/reports",
    financialReport:
      "/reports/[reportTemplateId]/financial-report/[financialPackageId]",
    financialReportTasks:
      "/reports/[reportTemplateId]/financial-report/[financialPackageId]/tasks",
    financialReportView:
      "/reports/[reportTemplateId]/financial-report/[financialPackageId]/view",
    financialReportPreview:
      "/reports/[reportTemplateId]/financial-report/[financialPackageId]/[packageComponentId]/preview",
    qboCallback: "/qbo/callback",
    goals: "/goals",
    goalProgress: "/goals/[goalId]/progress",
    profitRecommendations: "/profit-recommendations",
    budgetVsActual: "/budget-vs-actual",
    dataDiagnostics: "/data-diagnostics",
    reportingTemplate: "/reporting-template",
    templateBuilder: "/reporting-template/[reportTemplateId]/template-builder",
    preview:
      "/reporting-template/[reportTemplateId]/template-builder/[packageComponentId]/preview",
    configure:
      "/reporting-template/[reportTemplateId]/template-builder/[packageComponentId]/configure",
    settings: "/settings/client-portal",
    reportSettings: "/settings/report",
    publishingRequirementsSettings: "/settings/publishing-requirements",
    kpis: {
      types: "/kpis-types",
      stdKPIsList: "/std-kpis",
      stdKPIsDetails: "/std-kpis/[kpiId]",
      customKPIsList: "/custom-kpis",
      addCustomKPI: "/custom-kpis/add",
      customKPIDetails: "/custom-kpis/[kpiId]",
    },
    dataSets: {
      list: "/data-sets",
      addEditDataSet: "/data-sets/add",
      addDataSetData: "/data-sets/[dataSetId]/add-data",
    },
    arap: "/ar-ap",
  },
  invitation: {
    joinNow: "/join-now/[inviteCode]",
    reject: "/join-now/[inviteCode]/reject",
  },
  common: {
    base: "/",
    login: "/login",
    profile: "/profile",
  },
} satisfies IRouteObject;

const protectedAdminRoutes = Object.keys(routes.admin.client).map((r) => ({
  path: routes.admin.client[r],
  role: [],
}));
const protectedGlobalAdminRoutes = Object.keys(routes.admin.globalAdmin).map(
  (r) => ({
    path: routes.admin.globalAdmin[r],
    role: [],
  })
);
const protectedAgencyRoutes = Object.keys(routes.admin.agency).map((r) => ({
  path: routes.admin.agency[r],
  role: [],
}));
const protectedUsersRoutes = Object.keys(routes.admin.user).map((r) => ({
  path: routes.admin.user[r],
  role: [],
}));
const protectedKPIsRoutes = Object.keys(routes.admin.kpis).map((r) => ({
  path: routes.admin.kpis[r],
  role: [],
}));
const protectedClientRoutes = Object.keys(routes.client).map((r) => ({
  path: routes.client[r],
  role: [],
}));
const protectedClientKPIsRoutes = Object.keys(routes.client.kpis).map((r) => ({
  path: routes.client.kpis[r],
  role: [],
}));
const protectedClientDataSetsRoutes = Object.keys(routes.client.dataSets).map(
  (r) => ({
    path: routes.client.dataSets[r],
    role: [],
  })
);

export const protectedRoutes = [
  ...protectedAdminRoutes,
  ...protectedUsersRoutes,
  ...protectedKPIsRoutes,
  ...protectedClientRoutes,
  ...protectedClientKPIsRoutes,
  ...protectedClientDataSetsRoutes,
  ...protectedAgencyRoutes,
  ...protectedGlobalAdminRoutes,
  { path: routes.admin.emailTemplates, role: [] },
  { path: routes.admin.publishingRequirements, role: [] },
  { path: routes.admin.permissions, role: [] },
  {
    path: routes.common.profile,
    role: [],
  },
];

export function goToDashboard(
  isClientUser: boolean,
  isMultiClient: boolean = false
): string {
  if (isClientUser && isMultiClient) {
    return routes.client.landing as string;
  }
  if (isClientUser) {
    return routes.client.clientUserDashboard as string;
  }
  return routes.admin.agency.list as string;
}

// Uniq Query Strings, should not match with routes dynamic key
export const uniqQueryStrings: { [key: string]: string } = {
  clientId: "cId",
  dataSetId: "dsId",
  showClientUsers: "showClientUsers",
  isPreEditingKpi: "isPreEditingKpi",
  isCustomizingStdKpi: "isCustomizingStdKpi",
  aisAccountType: "aisType",
  tggUserId: "tuId",
  authToken: "uat",
  financialPackageId: "financialPackageId",
  reportTemplateId: "reportTemplateId",
  accountId: "aId",
};

export function queryHelper(
  route: string,
  addQuery: boolean,
  query: string
): string {
  if (addQuery) {
    return `${route}?${query}`;
  }
  return route;
}
