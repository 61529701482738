import useArrState from "@hooks/useArrState";
import useObjState from "@hooks/useObjState";
import {
  bracketItemIds,
  FormulaItemType,
  formulaItemTypes,
} from "@utils/constant/formula";
import {
  getInputRandomNumber,
  getRandomKpiBracketColor,
  KPIFormulaItem,
  KPIInputItem,
} from "@utils/helper/kpis";
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";

interface State {
  formulaName: string;
  formulaDescription: string;
  trailingPeriod?: number | null;
  outputType: string;
  outputDecimal: number;
}

const FormulaBuilderContext = createContext({
  state: {
    formulaName: "",
    formulaDescription: "",
    trailingPeriod: null,
    outputType: "",
    outputDecimal: 0,
  } as State,
  setState: (_state: State) => {},
  handleState: (_key: keyof State, _value: State[keyof State]) => {},
  clearState: () => {},
  formula: [] as Array<KPIFormulaItem>,
  setFormula: (_value: Array<KPIFormulaItem>) => {},
  removeFormulaItem: (_index: number) => {},
  inputs: [] as Array<KPIInputItem>,
  setInputs: (_value: Array<KPIInputItem>) => {},
  addInput: () => {},
  deleteInputByIndex: (_index: number) => {},
  updateInputsField: <K extends keyof KPIInputItem>(
    _index: number,
    _key: K,
    _value: KPIInputItem[K]
  ) => {},
  isLocked: true,
  setIsLocked: (_isLocked: boolean) => {},
  addBracketsToFormula: () => {},
  addOperatorToFormula: (_type: FormulaItemType, _id: string) => {},
  addInputToFormula: (_item: KPIFormulaItem) => {},
  isTestMode: false,
  setIsTestMode: (_isTestMode: boolean) => {},
  isDeleteMode: false,
  setIsDeleteMode: (_isDeleteMode: boolean) => {},
});

export const useFormulaBuilder = () => useContext(FormulaBuilderContext);

const FormulaBuilderProvider: FC<PropsWithChildren> = (props) => {
  const [isLocked, setIsLocked] = useState<boolean>(true);
  const [isTestMode, setIsTestMode] = useState<boolean>(false);
  const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false);
  const [state, setState, handleState, clearState] = useObjState<{
    formulaName: string;
    formulaDescription: string;
    trailingPeriod: {} | null;
    outputType: string;
    outputDecimal: number;
  }>({
    formulaName: "",
    formulaDescription: "",
    trailingPeriod: null,
    outputType: "number",
    outputDecimal: 0,
  });
  const [
    formula,
    setFormula,
    _addFormulaItem,
    _updateFormulaItem,
    removeFormulaItem,
  ] = useArrState<KPIFormulaItem>([]);
  const [
    inputs,
    setInputs,
    addInputsItem,
    _updateInputsItem,
    removeInputsItem,
    updateInputsField,
  ] = useArrState<KPIInputItem>([]);

  const addInput = useCallback(() => {
    addInputsItem({
      id: uuidv4(),
      selectedAccount: null,
      aggregation: "",
      trailingPeriod: null,
      value: "",
      testValue: getInputRandomNumber(inputs as never),
    });
  }, [addInputsItem, getInputRandomNumber, uuidv4]);

  const deleteInputByIndex = useCallback(
    (index: number) => {
      const inputItem = inputs[index].id;
      const arr = [...formula];
      removeInputsItem(index);
      setFormula(
        arr.filter(
          (a) => !(a.type === formulaItemTypes.input && a.id === inputItem)
        )
      );
    },
    [inputs, removeInputsItem, setFormula, formulaItemTypes]
  );

  const addOperatorToFormula = useCallback(
    (type: FormulaItemType, id: string) => {
      const formulaArr = [...formula];
      const lastInputTypeIndex = formulaArr.findLastIndex(
        (f) => f.type === formulaItemTypes.input
      );
      if (lastInputTypeIndex >= 0) {
        formulaArr.splice(lastInputTypeIndex, 0, { type, id });
      } else {
        formulaArr.splice(formulaArr.length - 1, 0, { type, id });
      }
      setFormula(formulaArr);
    },
    [formula, setFormula]
  );

  const addBracketsToFormula = useCallback(() => {
    const bracketColor = getRandomKpiBracketColor();

    const formulaArr = [...formula];
    formulaArr.splice(0, 0, {
      color: bracketColor,
      type: formulaItemTypes.bracket,
      id: bracketItemIds.open,
    });
    formulaArr.push({
      color: bracketColor,
      type: formulaItemTypes.bracket,
      id: bracketItemIds.close,
    });
    setFormula(formulaArr);
  }, [formula, setFormula, bracketItemIds, formulaItemTypes]);

  const addInputToFormula = useCallback(
    (item: KPIFormulaItem) => {
      const formulaArr = [...formula];
      const indexOfLastClosedBracket = formula.findLastIndex(
        (f) => f.type === formulaItemTypes.bracket
      );
      if (indexOfLastClosedBracket >= 0) {
        formulaArr.splice(indexOfLastClosedBracket, 0, item);
      } else {
        formulaArr.push(item);
      }
      setFormula(formulaArr);
    },
    [formula, setFormula, formulaItemTypes]
  );

  return (
    <FormulaBuilderContext.Provider
      value={{
        state,
        setState,
        handleState,
        clearState,
        formula,
        setFormula,
        removeFormulaItem,
        inputs,
        setInputs,
        addInput,
        deleteInputByIndex,
        updateInputsField,
        isLocked,
        setIsLocked,
        addBracketsToFormula,
        addOperatorToFormula,
        addInputToFormula,
        isTestMode,
        setIsTestMode,
        isDeleteMode,
        setIsDeleteMode,
      }}
    >
      {props.children}
    </FormulaBuilderContext.Provider>
  );
};

export default FormulaBuilderProvider;
